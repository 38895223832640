/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Form as BSForm,
  Button,
  Collapse,
  InputGroup,
  Pagination,
  Nav,
  Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import {
  FaCircle,
  FaLink,
  FaSearch,
  FaSlidersH,
  FaTimes,
} from 'react-icons/fa';
import {
  AiOutlineDownload,
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFileWord,
  AiOutlineFileZip,
} from 'react-icons/ai';
import { CgInfo } from 'react-icons/cg';
import queryString from 'query-string';
import { Element } from 'react-scroll';
import DefaultLayout from '@/layouts/default';
import Masthead from '@/components/layout/masthead';
import { filterData, formatDataAndExtractTags } from '@/utilities/general';

import usePagination from '@/hooks/use-pagination';
import SEO from '@/components/layout/seo';
import Showdown from 'showdown';
import { useForm } from 'react-hook-form';

const Page = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allToolsXlsxAllTools {
        nodes {
          tool_type
          title
          partner
          filename
          description
          audience
          topic
          id
          filetype
          filesize
          group
        }
      }
    }
  `);

  const dataset = formatDataAndExtractTags(
    data.allToolsXlsxAllTools.nodes,
    ['tool_type', 'topic', 'audience', 'group'],
    ['title', 'description']
  );

  const [allItems] = useState(dataset.data);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentTab, setCurrentTab] = React.useState('');

  const { register, getValues, reset, formState, setValue } = useForm();
  const { isDirty } = formState;

  const [menuOpen, setMenuOpen] = useState(false);
  const node = useRef();

  const changed = (e) => {
    const form = getValues();
    const filtered = filterData(allItems, form);
    setFilteredItems(filtered);
  };

  // handle the display of the menu on mobile
  const handleClick = (e) => {
    if (node.current.contains(e.target) || e.target.id === 'filterButton') {
      return;
    }
    setMenuOpen(false);
  };

  const submit = (e) => {
    e.preventDefault();
    changed();
  };

  const resetForm = (e) => {
    reset();
    tabFilter('');
  };

  const tabFilter = (topic) => {
    if (topic) {
      setValue('topicTags', [topic], { shouldDirty: true });
      setCurrentTab(topic);
    } else {
      setValue('topicTags', []);
      setCurrentTab('');
    }
    changed();
  };

  const [paginationItems, offset, limit] = usePagination(filteredItems.length);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    if (location.search) {
      const query = queryString.parse(location.search);
      let topic;
      if (query.topic == 'Implement') {
        topic = 'Implement&#44; Improve & Advocate';
      } else {
        topic = query.topic;
      }
      setValue('topicTags', topic ?? null, { shouldDirty: true });
      setValue('audienceTags', query.audience ?? null, { shouldDirty: true });
      setValue('tool_typeTags', query.type ?? null, { shouldDirty: true });
      tabFilter(topic);
    }
    changed();
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, setValue]);

  const dotMap = {
    Assessment: 'idm-lime',
    Brief: 'idm-sky-blue',
    Guide: 'idm-pink',
    'Related Websites': 'idm-gray',
    Template: 'idm-dark-lime',
  };

  const tips = {
    Assessment:
      '<strong>Assessments</strong>, such as the Implementation Development Map (IDM), are tools to evaluate and understand your system and context.',
    Brief:
      '<strong>Briefs</strong> provide additional information on topics such as policies, partnerships, and literature reviews.',
    Guide:
      '<strong>Guides</strong> help lead you through the process of using the assessment tools and templates. ',
    Template:
      '<strong>Templates</strong> support efforts to create partnerships, priorities, learning agendas, statewide program surveys, system analyses, and more. ',
  };

  const md = new Showdown.Converter();

  return (
    <DefaultLayout pageInfo={location}>
      <SEO title="Explore Tools" keywords={[]} />

      <Masthead location={location}>
        <Container className="my-4">
          <Row>
            <Col lg={6}>
              <h2>Explore Tools</h2>
              <p>
                The tools included in this section are for state leaders,
                researchers and advocates who are working to develop and sustain
                high-quality, equitable pre-kindergarten (pre-K) policies and
                practices.
              </p>
              <p>
                <a
                  href="/downloads/Navigating-the-PPI-Toolkit.pdf"
                  target="_blank"
                >
                  Understand more about navigating the PPI Toolkit
                </a>
              </p>
            </Col>
            <Col className="" lg={{ span: 3, offset: 2 }}>
              <div className="shadow featured-link mt-5">
                <Link to="/glossary/" className="standalone">
                  Glossary
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Masthead>
      <div className="shadow">
        <Container>
          <Nav
            variant="pills"
            className="flex-nowrap d-none d-lg-flex"
            activeKey={currentTab}
          >
            <Nav.Item>
              <Nav.Link onClick={() => tabFilter(null)} eventKey="">
                All Tools
              </Nav.Link>
            </Nav.Item>
            {dataset.sets.topic.map((topic, idx) => (
              <Nav.Item key={topic}>
                <Nav.Link
                  onClick={() => tabFilter(topic)}
                  eventKey={topic}
                  className="mx-3"
                >
                  {topic.replace('&#44;', ',')}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Form.Group controlId="area" className="py-4 d-block d-lg-none">
            <Form.Label>Area</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => tabFilter(e.target.value)}
            >
              <option key="" value="">
                All Tools
              </option>
              {dataset.sets.topic.map((topic, idx) => (
                <option key={topic} value={topic}>
                  {topic.replace('&#44;', ',')}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Container>
      </div>
      <Element name="scrollTop" />
      <Container className="mt-5">
        <Row>
          <Collapse in={menuOpen}>
            <Col lg={3} className="pr-5 filterMenu" id="filterMenu" ref={node}>
              <form onSubmit={submit}>
                <BSForm.Control type="hidden" ref={register} name="topicTags" />
                <BSForm.Group controlId="basic-filter">
                  <BSForm.Label>
                    <strong>Search Tools</strong>
                  </BSForm.Label>
                  <InputGroup>
                    <BSForm.Control
                      type="text"
                      ref={register}
                      onBlur={changed}
                      name="search"
                    />
                    {isDirty && (
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <Button href="#" onClick={resetForm}>
                            <FaTimes />
                            <span className="sr-only">Clear</span>
                          </Button>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    )}
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Button href="#" onClick={submit}>
                          <FaSearch />
                          <span className="sr-only">Search</span>
                        </Button>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </BSForm.Group>

                <div className="tag mb-3 mt-5 ">Audience</div>
                <div>
                  {dataset.sets.audience.sort().map((itm) => (
                    <div className="custom-control custom-checkbox" key={itm}>
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        name="audienceTags"
                        value={itm}
                        id={itm}
                        ref={register}
                        onChange={changed}
                      />
                      <label className="custom-control-label" htmlFor={itm}>
                        {itm}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="tag mb-3 mt-5 ">Resource Type</div>
                <div>
                  {dataset.sets.tool_type.sort().map((itm) => (
                    <div className="custom-control custom-checkbox" key={itm}>
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        name="tool_typeTags"
                        value={itm}
                        id={itm}
                        ref={register}
                        onChange={changed}
                      />
                      <label className="custom-control-label" htmlFor={itm}>
                        {itm}{' '}
                        {tips[itm] ? (
                          <OverlayTrigger
                            trigger="hover"
                            placement="right"
                            overlay={
                              <Popover>
                                <Popover.Content
                                  dangerouslySetInnerHTML={{
                                    __html: tips[itm],
                                  }}
                                ></Popover.Content>
                              </Popover>
                            }
                          >
                            <CgInfo className="popover-trigger-icon" />
                          </OverlayTrigger>
                        ) : (
                          ''
                        )}
                      </label>
                    </div>
                  ))}
                </div>
                <Button
                  type="submit"
                  onClick={() => {
                    changed();
                  }}
                  className="sr-only"
                >
                  Submit
                </Button>
              </form>
            </Col>
          </Collapse>
          <Col>
            <div className="d-flex">
              <h3 className="mb-0">
                {filteredItems.length}
                {filteredItems.length === 1 ? ' Result' : ' Results'}
              </h3>
              {isDirty && (
                <div className="">
                  <Button
                    onClick={(e) => {
                      resetForm(e);
                    }}
                    tabIndex="0"
                    className=""
                    variant="link"
                    size=""
                  >
                    <FaTimes className="align-baseline mr-1" size="12px" />
                    Clear Filters
                  </Button>
                </div>
              )}
              <div className="flex-grow-1" />

              <Button
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
                className="d-lg-none mb-0 dropdown-toggle"
                variant="link"
                aria-expanded={menuOpen}
                aria-controls="filterMenu"
                id="filterButton"
              >
                <FaSlidersH className="mr-2" size="16px" />
                Filters
              </Button>
            </div>
            <hr />
            {filteredItems &&
              filteredItems.slice(offset, limit).map((item) => (
                <div className="result-card position-relative" key={item.id}>
                  <Row>
                    <Col md={9}>
                      <h4 className="my-1">
                        <a
                          href={
                            item.filename.match(/^\//)
                              ? item.filename
                              : `/downloads/${item.filename}`
                          }
                          className="stretched-link no-ext "
                          target={item.filename.match(/^\//) ? '' : '_blank'}
                          rel="noreferrer"
                        >
                          {item.title}
                          {item.filename.match(/^\//) ? (
                            ''
                          ) : (
                            <AiOutlineDownload className="dl-icon" />
                          )}
                        </a>
                        
                      </h4>
                      <div className="mb-4 fileinfo d-flex align-items-center">
                        {
                          {
                            PDF: <AiOutlineFilePdf size="20px" />,
                            XLSX: <AiOutlineFileExcel size="20px" />,
                            DOCX: <AiOutlineFileWord size="20px" />,
                            ZIP: <AiOutlineFileZip size="20px" />,
                            LINK: <FaLink size="20px" />,
                          }[item.filetype]
                        }
                        <span className="d-inline-block pt-1 ml-1">
                          {item.filetype}
                          {item.filesize ? `, ${item.filesize}` : ''}
                        </span>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: md.makeHtml(item.description),
                        }}
                      />
                      <div className="tag">Developed by {item.partner}</div>
                    </Col>
                    <Col className="mt-3 mt-md-0">
                      <div className="rectangle-small background-idm-light-gray p-3">
                        {item.tool_typeTags &&
                          item.tool_typeTags.map((tag) => (
                            <p className="tag d-flex mb-1" key={tag}>
                              <FaCircle
                                className={`tagIcon text-${dotMap[tag]}`}
                                size="8px"
                                style={{ width: '10px', marginTop: '6px' }}
                              />
                              <span>{tag}</span>
                            </p>
                          ))}
                      </div>
                      <div>{item.filename.match(/PPI-IDM-Final/) ? 
                        <Link to="/app/idm/">  
                          <span className="btn btn-primary cute-button mt-3">Start a new Self-Assessment</span>
                        </Link> : ''}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            {paginationItems.length > 0 && (
              <div className="py-5 d-flex align-items-center">
                <span>Page</span>
                <Pagination className="mb-0 px-2">{paginationItems}</Pagination>
              </div>
            )}
            {paginationItems.length === 0 && (
              <p>
                <em>
                  Your filter or search returned no results. Please try again.{' '}
                  {isDirty && (
                    <Button
                      variant="link"
                      onClick={(e) => {
                        resetForm(e);
                      }}
                    >
                      Clear Filters
                    </Button>
                  )}
                </em>
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  );
};

Page.propTypes = {
  location: PropTypes.shape(),
};

Page.defaultProps = {
  location: null,
};

export default Page;
