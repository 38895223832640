import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import { scroller } from 'react-scroll';

export default (countItems) => {
  const perPage = 20;
  const [page, setPage] = useState(1);

  const newPage = (num) => {
    scroller.scrollTo('scrollTop', {
      smooth: true,
      offset: -25,
    });
    window.sessionStorage.setItem(`page-${window.location.pathname}`, num);
    setPage(num);
  };

  useEffect(() => {
    let savedPage = 1;
    if (window.sessionStorage.getItem(`page-${window.location.pathname}`)) {
      savedPage = parseInt(
        window.sessionStorage.getItem(`page-${window.location.pathname}`),
        10
      );
    }
    setPage(savedPage);
  }, [countItems, page]);

  const numPages = Math.ceil(countItems / perPage);

  const paginationItems = [];
  for (let number = 1; number <= numPages; number += 1) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === page}
        onClick={() => {
          newPage(number);
        }}
      >
        {number}
      </Pagination.Item>
    );
  }
  const offset = (page - 1) * perPage;
  const limit = page * perPage;
  return [paginationItems, offset, limit];
};
