/**
 * Filters an array of objects using custom predicates.
 * https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
function filterArray(array, filters) {
  const filterKeys = Object.keys(filters);
  // validates all filter criteria
  return array.filter((item) => filterKeys.every((key) => {
    // ignores non-function predicates
    if (typeof filters[key] !== 'function') return true;
    return filters[key](item[key]);
  }));
}

/**
 * This function takes in data from a graphql and splits the specified fields
 * into tags. The resulting field is then added to the data object with the text
 * "Tags" appended to the field name.
 *
 * @param {Array} data
 * @param {Array} fields
 */
export const formatDataAndExtractTags = (data, fields, search = null) => {
  const distinct = {};
  const updatedData = [];
  for (let i = 0; i < data.length; i += 1) {
    const tmp = data[i];
    for (let j = 0; j < fields.length; j += 1) {
      let tags = tmp[fields[j]].split(',');
      tags = tags.map((s) => s.trim());
      if (tags[0] !== '') {
        tmp[`${fields[j]}Tags`] = tags;
      } else {
        tmp[`${fields[j]}Tags`] = null;
      }
      // find the distinct tags
      if (!distinct[fields[j]]) distinct[fields[j]] = [];
      tags.forEach((itm) => {
        if (itm && distinct[fields[j]].indexOf(itm) === -1) distinct[fields[j]].push(itm);
      });
      if (search) {
        tmp.search = '';
        search.forEach((key) => {
          tmp.search += `${tmp[key]} `;
        });
      }
      tmp['sort-index'] = i;
      if (tmp.title) {
        tmp['sort-title'] = tmp.title.toLowerCase().replace(/(<([^>]+)>)/gi, '').trim();
      }
    }
    updatedData.push(tmp);
  }
  return { data: updatedData, sets: distinct };
};

/**
 * This takes an object produced by react form and filters
 * a data array based on it. If the form is empty, the full dataset
 * is returned.
 *
 * @param {Array} data
 * @param {Object} form
 */
export const filterData = (data, form) => {
  // pluck off the search
  const { search } = form;
  // eslint-disable-next-line no-param-reassign
  delete form.search;

  // return full dataset if the array is empty and there is no search
  if (Object.values(form).every((x) => !x.length) && !search) return data;

  // loop over the form data and build the filter query
  const filter = {};
  if (search) {
    filter.search = (field) => {
      const re = new RegExp(search, 'gi'); return field.match(re);
    };
  }

  // Deal with the rest of the tag lookups
  Object.keys(form).map((itm) => {
    if (form[itm].length > 0) {
      filter[itm] = (field) => field && field.find((x) => form[itm].includes(x));
    }
    return true;
  });
  return filterArray(data, filter);
};

export const filterPast = (array, field, fieldEnd) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const filteredArray = array.filter((itm) => {
    const itmValues = itm[field].split('-');
    const itmValuesEnd = fieldEnd ? itm[fieldEnd].split('-') : null;
    return new Date(itmValues[0], itmValues[1] - 1, itmValues[2]) >= today
      || (itmValuesEnd && new Date(itmValuesEnd[0], itmValuesEnd[1] - 1, itmValuesEnd[2]) >= today);
  });
  return filteredArray;
};
